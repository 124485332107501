import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate,useLocation , Form } from 'react-router-dom';
import Login from './component/Auth/Login';
import Dashboard from './component/Dashboard';
import DashboardUser from './component/DashboardUser';
import Header from './component/Header/Header';
import Footer from './component/Header/Footer';
import SideBar from './component/Header/SideBar';
import UserManager from './component/UserManager';
import ThanaManager from './component/ThanaUser';
import Trainer from './component/Trainer';
import Settings from './component/Settings';
import Report from './component/Report';
import AddAdmin from './component/Add Model/AddAdmin';
import AdminUser from './component/AdminUser';
import AddState from './component/Add Model/AddState';
import State from './component/StateList';
import AddDistrict from './component/Add Model/AddDistrict';
import District from './component/District';
import AddThana from './component/Add Model/AddThana';
import Thana from './component/Thana';
import AddAdminUser from './component/Add Model/AddAdminUser';
import Admin from './component/Admin';
import AddOperatorUser from './component/Add Model/AddOperator';
import OperatorUser from './component/Operator';
import UserPermission from './component/UserPermission';
import Enquiry from './component/Form';

const PrivateRoute = ({ children, isAuthenticated, role, requiredRole }) => {
  if (isAuthenticated === null) {
    return <div>Loading...</div>; // Or any other loading indicator
  }
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }
  if (requiredRole && !requiredRole.includes(role)) {
    return <Navigate to="/dashboard" />; // Or an appropriate "not authorized" page
  }
  return children;
};

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [role, setRole] = useState(null);
  // const location = useLocation();

  useEffect(() => {
    // Check if the user is authenticated and get their role
    const token = localStorage.getItem('authToken');
    const userRole = localStorage.getItem('userRole'); // Assuming role is stored in local storage
    if (token && userRole) {
      setIsAuthenticated(true);
      setRole(userRole);
    } else {
      setIsAuthenticated(false);
    }
  }, [role]);

  const Layout = ({ children, isAuthenticated }) => {
    const location = useLocation();
  
    // Define routes where the layout should be hidden
    const hideLayoutRoutes = ['/form'];
  
    const shouldHideLayout = hideLayoutRoutes.some((route) =>
      location.pathname.startsWith(route)
    );
  
    return (
      <>
        {!shouldHideLayout && isAuthenticated && <SideBar />}
        {!shouldHideLayout && isAuthenticated && <Header />}
        <main>{children}</main>
        {!shouldHideLayout && isAuthenticated && <Footer />}
      </>
    );
  };

  return (
    <Router>
      {/* {isAuthenticated && <Form />} */}
      {/* {!shouldHideLayout && isAuthenticated && <SideBar />}
      {!shouldHideLayout && isAuthenticated && <Header />} */}
      {/* <Layout isAuthenticated={isAuthenticated} /> */}
      <Layout isAuthenticated={isAuthenticated}>
      <Routes>
      <Route path="/form/:encodedId" element={<Enquiry />} />
        <Route
          path="/"
          element={
            isAuthenticated ? <Navigate to="/dashboard" /> : <Login setIsAuthenticated={setIsAuthenticated} setRole={setRole} />
          }
        />

        <Route
          path="/dashboard"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['Admin']}>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboardUser"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['Admin', 'User', 'CO']}>
              <DashboardUser />
            </PrivateRoute>
          }
        />
        <Route
          path="/user-permission"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['Admin', 'User', 'CO']}>
              <UserPermission />
            </PrivateRoute>
          }
        />
        
        <Route
          path="/users"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['Admin', 'User', 'CO']}>
              <UserManager />
            </PrivateRoute>
          }
        />

        <Route
          path="/profiles"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['Admin', 'User', 'CO']}>
              <ThanaManager />
            </PrivateRoute>
          }
        />
        <Route
          path="/trainer"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['Admin', 'Helper']}>
              <Trainer />
            </PrivateRoute>
          }
        />
        <Route
          path="/state"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['Admin', 'Helper']}>
              <State />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-state"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['Admin', 'Helper']}>
              <AddState />
            </PrivateRoute>
          }
        />
        <Route
          path="/district"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['Admin', 'Helper']}>
              <District />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-district"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['Admin', 'Helper']}>
              <AddDistrict />
            </PrivateRoute>
          }
        />
        <Route
          path="/thana"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['Admin', 'Helper']}>
              <Thana />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-thana"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['Admin', 'Helper']}>
              <AddThana />
            </PrivateRoute>
          }
        />
        <Route
          path="/thana"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['Admin', 'Helper']}>
              <Thana />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-admin-user"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['Admin', 'Helper']}>
              <AddAdminUser />
            </PrivateRoute>
          }
        />

        <Route
          path="/admin-user"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['Admin', 'Helper']}>
              <Admin />
            </PrivateRoute>
          }
        />

        <Route
          path="/operator-user"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['Admin', 'Helper', 'CO']}>
              <AdminUser />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-operator-user"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['Admin', 'CO']}>
              <AddAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path="/operatorUser"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['Admin', 'User', 'CO']}>
              <OperatorUser />
            </PrivateRoute>
          }
        />
        <Route
          path="/addOperatorUser"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['Admin', 'User', 'CO']}>
              <AddOperatorUser />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['Admin', 'User', 'CO']}>
              <Settings />
            </PrivateRoute>
          }
        />
        <Route
          path="/report"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['Admin', 'User', 'CO']}>
              <Report />
            </PrivateRoute>
          }
        />

      </Routes>
      </Layout>
      {/* {!shouldHideLayout && isAuthenticated && <Footer />} */}
    </Router>
  );
}

export default App;
