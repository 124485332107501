import React, { useEffect, useState, useMemo } from 'react';
import useAsync from '../hooks/useAsync';
import { Link } from 'react-router-dom';
import LinkForm from './Button/Link.js'
import UserServices from '../services/UserServices';
import ViewUser from './View Model/ViewUser';
import Modal from 'react-modal';
import UserStatus from './TogelButton/UserStatus';
import UpdateProfile from './Update Model/UpdateProfile.js';
import ReactPaginate from 'react-paginate';
import * as XLSX from 'xlsx';
Modal.setAppElement('#root');

function UserManager() {
  const [currentPage, setCurrentPage] = useState(1);  // State to track the current page
  const [search, setSearch] = useState('');
  const [data1, setData1] = useState();
  const [data, setData] = useState();
  const [temp, setTemp] = useState(0);
  const usersPerPage = 50;  // You can adjust this value as needed
  const [filteredData, setFilteredData] = useState();
  // const { data, error, isLoading, run } = useAsync(() => UserServices.getAllUser(currentPage, usersPerPage));  // Fetch data based on current page and limit

  useEffect(() => {
    UserServices.getAllUser(currentPage, search).then((res) => {
      setData(res)
    }).catch((error) => {
      console.log(error)
    })
  }, [currentPage, search, temp])
  // useEffect(() => {
  //   const fetchData = () => {
  //     UserServices.getAllUser(currentPage)
  //       .then(res => setData(res))
  //       .catch(err => console.error(err));
  //   };
  //   fetchData();
  // }, [currentPage]);

  // useEffect(() => {
  //   if (search) {
  //     UserServices.getAllUserSearch(search)
  //       .then(res => setFilteredData(res))
  //       .catch(err => console.error(err));
  //   } else {
  //     setFilteredData(data);
  //   }
  // }, [search, data]);
  // // console.log(">>>", data)
  const count = data?.totalProfiles;  // Total profiles count


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeIndex, setActiveIndex] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState(null);
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);
  const [selectedLink, setSelectedLink] = useState(null);
  const sanitizedQuery = searchQuery.trim();
  const handleEditDetails = (thana) => {
    setSelectedEdit(thana);
    setIsEditModalOpen(true);
    toggleActionMenu(null);
  };
  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedEdit(null);
  };
  const handleLinkDetails = (user) => {
    setSelectedLink(user);
    setIsLinkModalOpen(true);
    toggleActionMenu(null);
  };
  const closeLinkModal = () => {
    setIsLinkModalOpen(false);
    setSelectedLink(null);
  };
  const handleViewDetails = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);  // Update current page state
  };
  const toggleActionMenu = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    return `${day}-${month}-${year}`;
  };


  const filteredUsers = useMemo(() => {
    return data?.data?.filter(user => {
      return (
        user?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user?.user?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        String(user?.mobileNo || "").toLowerCase().includes(searchQuery.toLowerCase()) ||
        String(user?.verificationId || "").includes(searchQuery) ||
        user?.presentAddress?.policeStation?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user?.presentAddress?.state?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user?.presentAddress?.district?.name?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  }, [data, searchQuery]);

  const handleDownload = () => {
    // Construct API URL with optional search query
    const downloadUrl = `${process.env.REACT_APP_API_BASE_URL}/profile/download-excel${search ? `?search=${search}` : ''}`;


    // Trigger download by setting window location
    window.location.href = downloadUrl;
  };
  console.log(filteredUsers)
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div className="right_col" role="main">
      <div className="title-box">
        <h2>Satyapan <span className="badge bg-warning">{count}</span></h2>
      </div>
      <div className="sub-title-box">
        <div className="sub-title-box-left">
          
        </div>
           
        <div className="sub-title-box-right">
          
          <button className="excel-btn" onClick={handleDownload}><img src="img/excel.svg" />Download</button>
        </div>
      </div>
      <div className="container-box px-0 user-manager">
        <div className="container-box-top-header px-4">
          <div className="container-box-top-header-left-2">
            <input
              type="search"
              name="search"
              placeholder="Search"
              value={search}
              onChange={handleSearchChange}
            />
            <button className="search-btn">Search</button>
          </div>
        
        </div>
        <div className="container-box-inner">
          <table id="example" className="table table-striped" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>#</th>
                <th>App User</th>
                <th>Satyapan Name</th>
                <th>Aadhar No.</th>
                <th>Mobile No.</th>
                <th>Profession</th>
                <th>State</th>
                <th>District</th>
                <th>Police Station</th>
                <th>Verification ID</th>
                <th>Register Date</th>
                {/* <th>Status Label</th> */}
                <th>Status</th>
                <th>More Details</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers?.map((user, index) => (
                <tr key={user?._id}>
                  <td style={{ backgroundColor: user?.status === 'Verify' ? '#2eb577' : '#ef4656', color: 'white' }}>
                    {index + 1 + (currentPage - 1) * usersPerPage}
                  </td>
                  <td style={{ backgroundColor: user?.status === 'Verify' ? '#2eb577' : '#ef4656', color: 'white' }}>
                    <span >{user?.user?.name}</span>
                  </td>
                  <td style={{ backgroundColor: user?.status === 'Verify' ? '#2eb577' : '#ef4656', color: 'white' }}>
                    {user?.name}
                  </td>
                  <td style={{ backgroundColor: user?.status === 'Verify' ? '#2eb577' : '#ef4656', color: 'white' }}>
                    {user?.aadharNo}
                  </td>
                  <td style={{ backgroundColor: user?.status === 'Verify' ? '#2eb577' : '#ef4656', color: 'white' }}>
                    {user?.mobileNo}
                  </td>
                  <td style={{ backgroundColor: user?.status === 'Verify' ? '#2eb577' : '#ef4656', color: 'white' }}>
                    {user?.profession?.profession || 'N/A'}
                  </td>
                  <td style={{ backgroundColor: user?.status === 'Verify' ? '#2eb577' : '#ef4656', color: 'white' }}>
                    {user?.presentState?.name || 'N/A'}
                  </td>
                  <td style={{ backgroundColor: user?.status === 'Verify' ? '#2eb577' : '#ef4656', color: 'white' }}>
                    {user?.presentDistrict?.name || 'N/A'}
                  </td>
                  <td style={{ backgroundColor: user?.status === 'Verify' ? '#2eb577' : '#ef4656', color: 'white' }}>
                    {user?.presentPoliceStation?.name || 'N/A'}
                  </td>
                  <td style={{ backgroundColor: user?.status === 'Verify' ? '#2eb577' : '#ef4656', color: 'white' }}>
                    {user?.presentAddress?.policeStation?.code && user?.verificationId && user?.presentAddress?.district?.code
                      ? `${user.presentAddress.policeStation.code} ${user.verificationId} ${user.presentAddress.district.code}`
                      : user?.presentAddress?.policeStation?.code || user?.verificationId || user?.presentAddress?.district?.code
                        ? user.presentAddress.policeStation.code || user.verificationId || user?.presentAddress?.district?.code
                        : 'N/A'}
                  </td>
                  <td style={{ backgroundColor: user?.status === 'Verify' ? '#2eb577' : '#ef4656', color: 'white' }}>
                    {formatDate(user?.createdAt)}
                  </td>
                  {/* <td style={{ backgroundColor: user?.status === 'Verify' ? '#2eb577' : '#ef4656', color: 'white' }}>
                    <button
                      className="view-details-btn"
                      style={{
                        backgroundColor: user?.status === 'Verify' ? '#2eb577' : '#ef4656',
                        color: 'white'
                      }}>
                      {user?.status}
                    </button>
                  </td> */}
                  <td className="status-toggle" style={{ backgroundColor: user?.status === 'Verify' ? '#2eb577' : '#ef4656', color: 'white' }}><UserStatus user={user} page={"user"} /></td>
                  <td style={{ backgroundColor: user?.status === 'Verify' ? '#2eb577' : '#ef4656', color: 'white' }}>
                    <div className="action_toggle_outer">
                      <button
                        className="action_toggle"
                        onClick={() => toggleActionMenu(index)}
                      >
                        <i className="fa fa-ellipsis-v" />
                      </button>
                      {activeIndex === index && (
                        <div className="action_toggle_content product-edit show d-block">
                          <ul className="list-unstyled">
                            <li>
                              <button className="close_toggle" onClick={() => toggleActionMenu(null)}>
                                <i className="fa fa-close" />
                              </button>
                            </li>
                            <li>
                              <button className="edit" onClick={() => handleEditDetails(user)}>Edit</button>
                            </li>
                            <li>
                              <button className="view-details-btn" onClick={() => handleViewDetails(user)}>View</button>
                            </li>
                            <li>
                              <button className="edit"  style={{ backgroundColor: "#699836" }} onClick={() => handleLinkDetails(user)}>Link</button>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* Pagination */}
        <div className="pagination">
          <ReactPaginate
            pageCount={Math.ceil(count / usersPerPage)}  // Total pages
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={handlePageClick}  // Page change handler
            containerClassName={'pagination'}
            activeClassName={'active'}
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            initialPage={currentPage - 1}
            pageLinkClassName={'page-link'}
            previousLinkClassName={'page-link'}
            nextLinkClassName={'page-link'}
            pageClassName={'page-item'}
            previousClassName={'page-item'}
            nextClassName={'page-item'}
            disabledClassName={'disabled'}
          />
        </div>
      </div>
      <Modal
        isOpen={isEditModalOpen}
        onRequestClose={closeEditModal}
        contentLabel="Edit Category"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <UpdateProfile selectedData={selectedEdit} closeModal={closeEditModal} onSuccess={() => setTemp(temp + 1)} />
      </Modal>
    
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="User Details"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <ViewUser user={selectedUser} closeModal={closeModal} />
      </Modal>
      <Modal
        isOpen={isLinkModalOpen}
        onRequestClose={closeLinkModal}
        contentLabel="Link Category"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <LinkForm user={selectedLink} closeModal={closeLinkModal} />
      </Modal>
    </div>
  )
}
export default UserManager;


