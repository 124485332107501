import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import UserServices from '../../services/UserServices';
import AdminService from '../../services/AdminServices';

function AddAdmin() {
    const navigate = useNavigate();
    const [formValues, setFormValues] = useState({
        name: '',
        role: '',
        email: '',
        mobileNo: '',
        state: '',
        district: '',
        policeStation: '',
        pincode: '',
        password: '',
        circleName: '',
    });

    const [states, setStates] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [policeStations, setPoliceStations] = useState([]);
    const [circle, setCircle] = useState([]);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        const fetchStates = async () => {
            try {
                const response = await UserServices.getState();
                setStates(response.data);
            } catch (error) {
                console.error('Failed to fetch states', error);
            }
        };
        fetchStates();
    }, []);

    useEffect(() => {
        const fetchDistricts = async () => {
            if (formValues.state) {
                try {
                    const response = await UserServices.getDistrictByState(formValues.state);
                    setDistricts(response.data);
                } catch (error) {
                    console.error('Failed to fetch districts', error);
                }
            }
        };
        fetchDistricts();
    }, [formValues.state]);

    useEffect(() => {
        const fetchPoliceStations = async () => {
            if (formValues.district && formValues.role !== 'CO') {
                try {
                    const response = await UserServices.getThanaByDistrict(formValues.district);
                    setPoliceStations(response.data);
                } catch (error) {
                    console.error('Failed to fetch police stations', error);
                }
            }
        };
        fetchPoliceStations();
    }, [formValues.district, formValues.role]);

    useEffect(() => {
        const fetchCircle = async () => {
            if (formValues.district && formValues.role === 'CO') {
                try {
                    const response = await UserServices.getCircleByDistrict(formValues.district);
                    setCircle(response.data);
                } catch (error) {
                    console.error('Failed to fetch circle name', error);
                }
            }
        };
        fetchCircle();
    }, [formValues.district, formValues.role]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Log formValues to debug
        // console.log("Form Values:", formValues);

        try {
            const response = await AdminService.createAdmin(formValues);
            if (response.status === 201) {
                alert('Admin user created successfully');
                navigate('/admin-user');
            } else {
                alert('Admin user created successfully');
                navigate('/admin-user');
            }
        } catch (error) {
            console.error('Failed to create Admin user', error);

            // Check if the error is a duplicate key error
            if (error.response && error.response.data) {
                // Assuming MongoDB duplicate key error format
                const duplicateKeyError = error.response.data.message || '';
                if (duplicateKeyError.includes('duplicate key error') ||
                    duplicateKeyError.includes('dup key:')) {
                    alert('Email or Mobile number already exists.');
                } else {
                    alert('Email and Mobile number already exists: ' + duplicateKeyError);
                }
            } else {
                alert('An error occurred while creating the Admin user.');
            }
        }
    };

    return (
        <div className="right_col" role="main">
            <div className="title-box">
                <h2>Admin User Add</h2>
                <div className="container-box-top-header-right">
                    <Link className="list-banner-btn" to="/admin-user">User List</Link>
                </div>
            </div>
            <div className="container-box px-5">
                <div className="container-box-inner">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-details">
                                <form onSubmit={handleSubmit}>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="input-field">
                                                <label>User Type</label>
                                                <select className="form-control" value={formValues.role}
                                                    onChange={handleInputChange} name="role">
                                                    <option value="">--Select Type--</option>
                                                    <option value="Admin">Nodal Officer </option>
                                                    <option value="User">P.S. Head</option>
                                                    <option value="CO">Circle Head</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="input-field">
                                                <label>Name</label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={formValues.name}
                                                    onChange={handleInputChange}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="input-field">
                                                <label>Email</label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    value={formValues.email}
                                                    onChange={handleInputChange}
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="input-field">
                                                <label>Mobile No.</label>
                                                <input
                                                    type="text" // Use text to avoid issues with maxLength
                                                    name="mobileNo"
                                                    maxLength={10}
                                                    value={formValues.mobileNo}
                                                    onChange={handleInputChange}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="input-field">
                                                <label>State</label>
                                                <select
                                                    className="form-control"
                                                    name="state"
                                                    value={formValues.state}
                                                    onChange={handleInputChange}
                                                    required
                                                >
                                                    <option value="">--Select State--</option>
                                                    {states.map((state) => (
                                                        <option key={state._id} value={state._id}>
                                                            {state.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="input-field">
                                                <label>District</label>
                                                <select
                                                    className="form-control"
                                                    name="district"
                                                    required
                                                    value={formValues.district}
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="">--Select District--</option>
                                                    {districts.map((district) => (
                                                        <option key={district._id} value={district._id}>
                                                            {district.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        {formValues.role === 'CO' && (
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input-field">
                                                    <label>Circle Name</label>
                                                    <select
                                                        className="form-control"
                                                        name="circleName"
                                                        // required
                                                        value={formValues.circleName}
                                                        onChange={handleInputChange}
                                                    >
                                                        <option value="">--Select Circle Name--</option>
                                                        {circle.map((circle) => (
                                                            <option key={circle} value={circle}>
                                                                {circle}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        )}

                                        {/* Conditionally show "Police Station" if the role is not 'CO' */}
                                        {formValues.role !== 'CO' && (
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input-field">
                                                    <label>Police Station</label>
                                                    <select
                                                        className="form-control"
                                                        name="policeStation"
                                                        required
                                                        value={formValues.policeStation}
                                                        onChange={handleInputChange}
                                                    >
                                                        <option value="">--Select Police Station--</option>
                                                        {policeStations.map((policeStation) => (
                                                            <option key={policeStation._id} value={policeStation._id}>
                                                                {policeStation.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        )}

                                        <div className="col-lg-4 col-md-6">
                                            <div className="input-field">
                                                <label>Pincode</label>
                                                <input
                                                    type="text" // Use text to avoid issues with maxLength
                                                    name="pincode"
                                                    value={formValues.pincode}
                                                    onChange={handleInputChange}
                                                    className="form-control"
                                                    maxLength={6}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="input-field">
                                                <label>Password</label>
                                                <div className="password-container" style={{ position: 'relative' }}>
                                                    <input
                                                        type={showPassword ? 'text' : 'password'}
                                                        name="password"
                                                        value={formValues.password}
                                                        onChange={handleInputChange}
                                                        className="form-control"
                                                        required
                                                    />
                                                    <span
                                                        className="toggle-password"
                                                        onClick={() => setShowPassword((prev) => !prev)}
                                                        style={{
                                                            position: 'absolute',
                                                            top: '50%',
                                                            right: '10px',
                                                            transform: 'translateY(-50%)',
                                                            cursor: 'pointer',
                                                            color: '#000',
                                                        }}
                                                    >
                                                        {showPassword ? (
                                                            <i className="fa fa-eye-slash"></i>
                                                        ) : (
                                                            <i className="fa fa-eye"></i>
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <button type="submit" className="submit-green-btn">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddAdmin;
