import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import UserServices from "../services/UserServices";
import FormServices from "../services/FormServices";
import "./Header/Css/form.css";
import ukimage from "./Header/Img/ukPolice.png";
function UserForm({ closeModal }) {
    const { encodedId } = useParams(); // Get encoded ID from URL
    const [user, setUser] = useState(null);
    const [form, setForm] = useState(null);
    const decodedId = atob(encodedId); // Decode Base64 ID
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [hasCriminalRecord, setHasCriminalRecord] = useState(null); // State for criminal record
    const [firs, setFirs] = useState([{ id: 1, value: "" }]); // Manage FIR inputs
    const [formValues, setFormValues] = useState({
        profile: decodedId,
        criminalRecord: "",
        fir: [],
        mobileNo: "",
        officerName: "",
        officerMobileNo: "",
        designation: "",
    });
    const [isFormFilled, setIsFormFilled] = useState(false); // Track if form is filled

    // Fetch user data on component mount
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await UserServices.getsingleData(decodedId);
                setUser(response.data);
            } catch (err) {
                setError("Failed to fetch user data.");
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
        fetchUserData();
    }, [decodedId]);

    // Fetch form data and check if it's filled
    useEffect(() => {
        const fetchFormData = async () => {
            try {
                const response = await FormServices.getSingleFormData(decodedId);
                setForm(response.data);

                // If form data is present, populate form fields and set filled state
                if (response.data) {
                    const firData = response.data.fir.map((fir, index) => ({
                        id: index + 1,
                        value: fir,
                    }));

                    setFormValues({
                        profile: response.data.profile || decodedId,
                        criminalRecord: response.data.criminalRecord || "",
                        officerName: response.data.officerName || "",
                        officerMobileNo: response.data.officerMobileNo || "",
                        designation: response.data.designation || "",
                    });

                    setFirs(firData.length > 0 ? firData : [{ id: 1, value: "" }]);
                    setHasCriminalRecord(response.data.criminalRecord === "Yes");
                    setIsFormFilled(true); // Mark form as filled
                }
            } catch (err) {
                setError("Failed to fetch form data.");
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
        fetchFormData();
    }, [decodedId]);

    const handleAddFIR = () => {
        setFirs([...firs, { id: firs.length + 1, value: "" }]);
    };

    const handleFIRChange = (id, value) => {
        setFirs(firs.map((fir) => (fir.id === id ? { ...fir, value } : fir)));
    };

    const handleRemoveFIR = (id) => {
        setFirs(firs.filter((fir) => fir.id !== id));
    };

    const handlePrint = () => {
        window.print();
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const firNumbers = firs.map((fir) => fir.value); // Collect all FIR numbers
            const finalFormValues = {
                ...formValues,
                criminalRecord: hasCriminalRecord ? "Yes" : "No",
                fir: hasCriminalRecord ? firNumbers : [],
            };

            await FormServices.createForm(finalFormValues);
            alert("Form submitted successfully!");
        } catch (error) {
            console.error("Failed to submit the form", error);
            alert("Failed to submit the form.");
        }
    };
    console.log(user);
    return (
        <div className="user-form-container" style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>



            <div className="box top-info">
                <img
                    src={ukimage}
                    alt="Logo"
                    style={{
                        float: "left",
                        marginRight: "15px",
                        width: "100px", // Optional: Adjust size to fit layout
                        height: "auto"
                    }}
                />
                <h2 style={{ marginRight: "100px" }} >Uttarakhand Police District Nainital</h2>
                <p style={{ marginRight: "100px" }}>
                    S.S City Haldwani, Officer Nainital Road, Near Police Station Haldwani
                    <br />
                    05946-231534, addhsp@gmail.com
                </p>
                <button
                    
                    onClick={handlePrint}
                    style={{ marginRight: "100px", marginTop: "10px", padding: "10px 20px", cursor: "pointer", backgroundColor: "#223973", color: "#fff" }}
                >
                    Print
                </button>
            </div>

            <div style={{ border: "1px solid #ccc", padding: "20px", borderRadius: "8px" }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "20px",
                    }}
                >
                    <img
                        src={user?.image ? `${process.env.REACT_APP_URL}${user?.image}` : "img/profile-img.png"}
                        alt="User"
                        style={{
                            height: "300px",
                            width: "300px",
                            objectFit: "contain",
                        }}
                    />
                </div>
                <div style={{ marginBottom: "10px", display: "flex", flexWrap: "wrap", gap: "20px" }}>
                    <p style={{ flex: "1 1 30%" }}>
                        <strong>Name:</strong> {user?.name}
                    </p>
                    <p style={{ flex: "1 1 30%" }}>
                        <strong>Age:</strong> {user?.age}
                    </p>
                    <p style={{ flex: "1 1 30%" }}>
                        <strong>Gender:</strong> {user?.gender}
                    </p>
                </div>

                <h4 style={{ marginTop: "20px" }}>Permanent Address:</h4>
                <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
                    <p style={{ flex: "1 1 30%" }}>
                        <strong>Address:</strong> {user?.permanentAddress?.mohalla}
                    </p>
                    <p style={{ flex: "1 1 30%" }}>
                        <strong>Ward No:</strong> {user?.permanentAddress?.ward || 'N/A'}
                    </p>
                    <p style={{ flex: "1 1 30%" }}>
                        <strong>Police Station:</strong> {user?.permanentAddress?.policeStation?.name}
                    </p>
                    <p style={{ flex: "1 1 30%" }}>
                        <strong>District:</strong> {user?.permanentAddress?.district?.name}
                    </p>
                    <p style={{ flex: "1 1 30%" }}>
                        <strong>State:</strong> {user?.permanentAddress?.state?.name}
                    </p>
                    <p style={{ flex: "1 1 30%" }}>
                        <strong>Pincode:</strong> {user?.permanentAddress?.pinCode}
                    </p>
                </div>

                <form onSubmit={handleSubmit}>
                    <h4 style={{ marginTop: "20px" }}>Does the above person have any criminal record?</h4>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="criminalRecord"
                                value={formValues.criminalRecord}
                                checked={hasCriminalRecord === true}
                                onChange={() => setHasCriminalRecord(true)}
                            />
                            Yes
                        </label>
                        <label style={{ marginLeft: "20px" }}>
                            <input
                                type="radio"
                                name="criminalRecord"
                                value={formValues.criminalRecord}
                                checked={hasCriminalRecord === false}
                                onChange={() => setHasCriminalRecord(false)}
                            />
                            No
                        </label>
                    </div>

                    {hasCriminalRecord === true && (
                        <div style={{ marginTop: "20px" }}>
                            <h4>FIR No.U/S , Date/Year, Police Station, CS/FR
                                *</h4>
                            {firs.map((fir) => (
                                <div
                                    key={fir.id}
                                    style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
                                >
                                    <input
                                        type="text"
                                        value={fir.value}
                                        onChange={(e) => handleFIRChange(fir.id, e.target.value)}
                                        placeholder="Your Answer"
                                        style={{ marginRight: "10px", padding: "5px", width: "80%" }}
                                    />
                                    {firs.length > 1 && (
                                        <button
                                            type="button"
                                            onClick={() => handleRemoveFIR(fir.id)}
                                            style={{ padding: "5px", cursor: "pointer", backgroundColor: "#dc3545", color: "#fff" }}

                                        >
                                            Remove
                                        </button>
                                    )}
                                </div>
                            ))}
                            <button
                                type="button"
                                onClick={handleAddFIR}
                                className="submit-green-btn"
                                style={{ marginTop: "10px", padding: "5px 10px", cursor: "pointer", backgroundColor: "#223973", color: "#fff" }}
                            >
                                Add More FIR
                            </button>
                        </div>
                    )}

                    {hasCriminalRecord !== null && (
                        <div style={{ marginTop: "20px" }}>
                            <h4>Above details verified by:</h4>
                            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "15px" }}>
                                <div style={{ width: "48%" }}>
                                    <label style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>
                                        Officer's Name
                                    </label>
                                    <input
                                        type="text"
                                        name="officerName"
                                        value={formValues.officerName}
                                        onChange={handleInputChange}
                                        placeholder="Enter officer's name"
                                        style={{
                                            padding: "8px",
                                            width: "100%",
                                            border: "1px solid #ccc",
                                            borderRadius: "4px",
                                        }}
                                    />
                                </div>

                                <div style={{ width: "48%" }}>
                                    <label style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>
                                        Officer's Mobile No.
                                    </label>
                                    <input
                                        type="text"
                                        name="officerMobileNo"
                                        value={formValues.officerMobileNo}
                                        onChange={handleInputChange}
                                        placeholder="Enter officer's mobile number"
                                        style={{
                                            padding: "8px",
                                            width: "100%",
                                            border: "1px solid #ccc",
                                            borderRadius: "4px",
                                        }}
                                    />
                                </div>
                            </div>

                            <div style={{ marginBottom: "15px" }}>
                                <label style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>
                                    Designation
                                </label>
                                <select
                                    name="designation"
                                    value={formValues.designation}
                                    onChange={handleInputChange}
                                    style={{
                                        padding: "8px",
                                        width: "100%",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px",
                                    }}
                                >
                                    <option value="">Choose</option>
                                    <option value="SSP">SSP</option>
                                    <option value="SP">SP</option>
                                    <option value="Dy. SP">Dy. SP</option>
                                    <option value="Inspector">Inspector</option>
                                    <option value="Sub Inspector">Sub Inspector</option>
                                    <option value="Add. Sub Inspector">Add. Sub Inspector</option>
                                    <option value="Head Constable">Head Constable</option>
                                    <option value="Constable">Constable</option>
                                </select>
                            </div>
                        </div>

                    )}

                    {!isFormFilled && (
                        <div style={{ marginTop: "20px", display: "flex", textAlign: "center", justifyContent: "flex-end" }}>
                            <button type="submit" style={{ padding: "10px 20px", cursor: "pointer", backgroundColor: "#223973", color: "#fff" }}>
                                Submit
                            </button>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
}

export default UserForm;
