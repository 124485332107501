import React, { useState } from 'react'; 
import UserServices from '../services/UserServices';
import Modal from 'react-modal';

Modal.setAppElement('#root');

function Report() { 
  const [search, setSearch] = useState('');

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleDownload = () => {
    // Construct API URL with optional search query
    const downloadUrl = `${process.env.REACT_APP_API_BASE_URL}/profile/download-excel${search ? `?search=${search}` : ''}`;


    // Trigger download by setting window location
    window.location.href = downloadUrl;
  };

  return (
    <div className="right_col" role="main">
      <div className="title-box">
        <h2>Report Download</h2>
      </div>
      <div className="sub-title-box">
        <div className="sub-title-box-left">         
        </div>
        <div className="sub-title-box-right">
          <button className="excel-btn" onClick={handleDownload}>
            <img src="img/excel.svg" alt="Excel Icon" />Download
          </button>
        </div>
      </div>
      <div className="container-box px-0 user-manager">
        <div className="container-box-top-header px-4">
          <div className="container-box-top-header-left-2">
            <input
              type="search"
              name="search"
              placeholder="Search"
              value={search}
              onChange={handleSearchChange}
            />
            <button className="search-btn">Search</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Report;
