import requests from "./httpService";

const AdminServices = {

    getMyProfile : async () =>{
        return requests.get(`/admin/profile`);
    },
    updateMe : async (body) =>{
        return requests.patch(`/admin/updateMe`,body);
    },
    createAdmin : async (body) =>{
        console.log(body)
        return requests.post(`/admin/`,body);
    },
    updatePassword : async (body) =>{
        return requests.patch(`/admin/change-password`,body);
    },
    dashboard : async () =>{
        return requests.get(`/admin/dashboard`);
    },
    dashboardUser : async () =>{
        return requests.get(`/admin/dashboardUser`);
    },
    top5Thana : async () =>{
        return requests.get(`/admin/topthana`);
    },
    top5User : async () =>{
        return requests.get(`/admin/topuser`);
    },
    top5DashboardThanaUser : async () =>{
        return requests.get(`/admin/dashboard/topuser`);
    },
    getAllAdmin : async () =>{
        return requests.get(`/admin`);
    },
    updateAdmin: async (id,body) =>{
        // console.log(">>>>",body)
        return requests.patch(`/admin/user/${id}`,body);
    },
    deleteAdmin: async (id,body) =>{
        return requests.delete(`/admin/user/${id}`,body);
    },
    login: async (body) =>{
        return requests.post(`/admin/login`,body);
    },

    
}


export default AdminServices;