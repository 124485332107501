import requests from "./httpService";

const FormServices = {

    getForm : async () =>{
        return requests.get(`/form`);
    },
    getSingleFormData : async (id) =>{
        // console.log(id)
        return requests.get(`/form/${id}`);
    },
    createForm : async (body) =>{
        return requests.post(`/form`,body);
    },

 
}

export default FormServices;