import React, { useState, useEffect } from "react";

function LinkForm({ user, closeModal }) {
  const [message, setMessage] = useState("");
  const [link, setLink] = useState("");
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (user) {
      const userId = user?._id;
      const { presentAddress, permanentAddress } = user;

      // Compare the specified fields
      const isMatching = ["mohalla", "state", "district", "policeStation", "pinCode"].every(
        (field) => presentAddress[field] === permanentAddress[field]
      );

      // Set the message and link based on the comparison
      if (isMatching) {
        setMessage("Not Applicable");
        setLink(""); // No link in case of non-applicable
      } else {
        const encodedUserId = btoa(userId); // Encode the userId using Base64
        const generatedLink = `https://pehchan.accredipath.com/form/${encodedUserId}`;
        setMessage(`${generatedLink}`);
        setLink(generatedLink);
      }
    }
  }, [user]);

  // Copy link to clipboard
  const copyToClipboard = () => {
    if (link) {
      navigator.clipboard.writeText(link).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
      });
    }
  };

  return (
    <div
      className="modal fade edit-box show d-block"
      id="editModal"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Verification Link
            </h5>
            <button type="button" className="btn-close" onClick={closeModal} aria-label="Close"></button>
          </div>
          <div className="modal-body" style={{ textAlign: "center", fontWeight: "500" }}>
            <h4>{message}</h4>
            <br />
            {link && (
              <div>
                <button
                  className="btn btn-primary"
                  onClick={copyToClipboard}
                  style={{ marginRight: "10px" }}
                >
                  Copy Link
                </button>
                {copied && <span style={{ color: "green" }}>Link copied!</span>}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LinkForm;
