import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'; // Don't forget to import PropTypes
import UserServices from '../../services/UserServices';
import StateServices from '../../services/StateServices.js';

function ProfileUpdate({ selectedData, closeModal, onSuccess }) {
    // console.log(">>>", selectedData);
    const [formValues, setFormValues] = useState({
        name: '',
        age: '',
        fathersName: '',
        mobileNo: '',
        aadharNo: '',
        presentAddress: {
            mohalla: '',
            ward: '',
            pinCode:'',
            village: '',
            policeStation: '',
            district: '',
            state: '',
            pinCode: '',
        },
        permanentAddress: {
            mohalla: '',
            ward: '',
            pinCode:'',
            policeStation: '',
            district: '',
            state: '',
            pinCode: '',
        },
        familyMembers: [
            {
                name: '',
                age: '',
                relation: '',
                mobileNo: '',
                aadhar: '',

            }
        ],
        idType: '',
        gender: '',
        image: '',
    });

    const [states, setStates] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [policeStations, setPoliceStations] = useState([]);
    const [previewImage, setPreviewImage] = useState('img/placeholder-img.png');

    // Fetch states on component mount
    useEffect(() => {
        const fetchStates = async () => {
            try {
                const response = await UserServices.getState();
                setStates(response.data);
            } catch (error) {
                console.error('Failed to fetch states', error);
            }
        };
        fetchStates();
    }, []);

    // Fetch districts based on selected state
    useEffect(() => {
        const fetchDistricts = async () => {
            if (formValues.presentAddress.state) {
                try {
                    const response = await UserServices.getDistrictByState(formValues.presentAddress.state);
                    setDistricts(response.data);
                } catch (error) {
                    console.error('Failed to fetch districts', error);
                }
            }
        };
        fetchDistricts();
    }, [formValues.presentAddress.state]);

    // Fetch police stations based on selected district
    useEffect(() => {
        const fetchThanas = async () => {
            if (formValues.presentAddress.district) {
                try {
                    const response = await UserServices.getThanaByDistrict(formValues.presentAddress.district);
                    setPoliceStations(response.data);
                } catch (error) {
                    console.error('Failed to fetch police stations', error);
                }
            }
        };
        fetchThanas();
    }, [formValues.presentAddress.district]);

    // Set form values when selectedData is available
    useEffect(() => {
        if (selectedData) {
            setFormValues({
                name: selectedData.name || '',
                age: selectedData.age || '',
                fathersName: selectedData.fathersName || '',
                mobileNo: selectedData.mobileNo || '',
                aadharNo: selectedData.aadharNo || '',
                presentAddress: {
                    mohalla: selectedData.presentAddress?.mohalla || '',
                    ward: selectedData.presentAddress?.ward || '',
                    pinCode: selectedData.presentAddress?.pinCode || '',
                    policeStation: selectedData.presentAddress?.policeStation || '',
                    district: selectedData.presentAddress?.district || '',
                    state: selectedData.presentAddress?.state || '',
                    pinCode: selectedData.presentAddress?.pinCode || '',
                },
                permanentAddress: {
                    mohalla: selectedData.permanentAddress?.mohalla || '',
                    ward: selectedData.permanentAddress?.ward || '',
                    pinCode: selectedData.permanentAddress?.pinCode || '',

                    // policeStation: selectedData.permanentAddress?.policeStation?._id || '',
                    policeStation: selectedData.permanentAddress?.policeStation || '',
                    district: selectedData.permanentAddress?.district || '',
                    state: selectedData.permanentAddress?.state || '',
                    pinCode: selectedData.permanentAddress?.pinCode || '',
                },
                familyMembers: selectedData.familyMembers.length ? selectedData.familyMembers : [{
                    name: '',
                    age: '',
                    relation: '',
                    mobileNo: '',
                    aadhar: '',
                }],
                idType: selectedData.idType || '',
                gender: selectedData.gender || '',
                image: selectedData.image || null,
            });
            if (selectedData.image) {
                setPreviewImage(process.env.REACT_APP_URL + selectedData.image);
            }
        }
    }, [selectedData]);

    // Handle input changes
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        const [parentKey, childKey] = name.split('.');

        if (childKey) {
            // Update nested fields (presentAddress/permanentAddress)
            setFormValues((prevValues) => ({
                ...prevValues,
                [parentKey]: {
                    ...prevValues[parentKey],
                    [childKey]: value,
                },
            }));
        } else {
            setFormValues((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));
        }
    };

    // Handle image change
    // const handleImageChange = (event) => {
    //     const file = event.target.files[0];
    //     setFormValues({ ...formValues, image: file });

    //     // Preview the uploaded image
    //     const reader = new FileReader();
    //     reader.onloadend = () => {
    //         setPreviewImage(reader.result);
    //     };
    //     if (file) {
    //         reader.readAsDataURL(file);
    //     }
    // };
    const handleImageChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const allowedTypes = ['image/jpeg', 'image/jpg'];
            if (!allowedTypes.includes(file.type)) {
                alert('Only JPEG and JPG files are allowed!');
                event.target.value = ''; // Reset the input value to clear the invalid file
                return;
            }

            // Update form state only if the file type is valid
            setFormValues({ ...formValues, image: file });

            // Preview the uploaded image
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    // Handle family member changes
    const handleFamilyMemberChange = (index, event) => {
        const { name, value } = event.target;
        const updatedFamilyMembers = [...formValues.familyMembers];
        updatedFamilyMembers[index] = { ...updatedFamilyMembers[index], [name]: value };
        setFormValues({ ...formValues, familyMembers: updatedFamilyMembers });
    };

    // Add a new family member
    const handleAddFamilyMember = () => {
        setFormValues({
            ...formValues,
            familyMembers: [...formValues.familyMembers, { name: '', age: '', relation: '', mobileNo: '', aadhar: '' }],
        });
    };

    // Remove a family member
    const handleRemoveFamilyMember = (index) => {
        const updatedFamilyMembers = formValues.familyMembers.filter((_, i) => i !== index);
        setFormValues({ ...formValues, familyMembers: updatedFamilyMembers });
    };
    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();

        // Prepare form data
        const formData = new FormData();
        formData.append('name', formValues.name);
        formData.append('age', formValues.age);
        formData.append('fathersName', formValues.fathersName);
        formData.append('mobileNo', formValues.mobileNo);
        formData.append('aadharNo', formValues.aadharNo);
        formData.append('presentAddress', JSON.stringify(formValues.presentAddress));
        formData.append('permanentAddress', JSON.stringify(formValues.permanentAddress));
        formData.append('familyMembers', JSON.stringify(formValues.familyMembers));
        formData.append('idType', formValues.idType);
        formData.append('gender', formValues.gender);
        if (formValues.image) {
            formData.append('image', formValues.image);
        }

        try {
            const res = await UserServices.updateUser(selectedData._id, formData);
            if (res.status === true) {
                alert('Profile updated successfully');
                onSuccess();
                closeModal();
            } else {
                // alert('Something went wrong');
                alert(res.message || 'Something went wrong');
            }
        } catch (error) {
            // Log error for debugging
            console.error('Failed to update profile', error);

            // Check if the error response has a message from the backend
            const errorMessage = error.response?.data?.message || 'An error occurred while updating the profile.';
            alert(errorMessage); // Show backend error message

        }
    };

    return (
        <div className="modal fade edit-box show d-block" id="editModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Edit Profile </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={closeModal} aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        <div className="container-box px-5">
                            <div className="container-box-inner">
                                <div className="page-details">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            {/* Name */}
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input-field d-flex ">
                                                    <label>Name:</label>
                                                    <input type="text" name="name" value={formValues.name} onChange={handleInputChange} className="form-control" required />
                                                </div>
                                            </div>

                                            {/* Age */}
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input-field d-flex">
                                                    <label>Age:</label>
                                                    <input type="text" name="age" value={formValues.age} onChange={handleInputChange} className="form-control" required />
                                                </div>
                                            </div>

                                            {/* Father's Name */}
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input-field d-flex">
                                                    <label>Father's Name:</label>
                                                    <input type="text" name="fathersName" value={formValues.fathersName} onChange={handleInputChange} className="form-control" required />
                                                </div>
                                            </div>

                                            {/* Mobile Number */}
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input-field d-flex">
                                                    <label>Mobile No:</label>
                                                    <input type="text" name="mobileNo" value={formValues.mobileNo} onChange={handleInputChange} className="form-control" required />
                                                </div>
                                            </div>

                                            {/* Aadhar Number */}
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input-field d-flex">
                                                    <label>Aadhar No:</label>
                                                    <input type="text" name="aadharNo" value={formValues.aadharNo} onChange={handleInputChange} className="form-control" required />
                                                </div>
                                            </div>
                                            {/* Gender */}
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input-field d-flex">
                                                    <label>Gender:</label>
                                                    <select name="gender" value={formValues.gender} onChange={handleInputChange} className="form-control" required>
                                                        <option value="">Select</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <br />
                                            <h4 style={{color:'#677788'}}><strong>Present Address:</strong></h4><br />
                                            {/* - Mohalla */}
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input-field d-flex">
                                                    <label>Mohalla:</label>
                                                    <input type="text" name="presentAddress.mohalla" value={formValues.presentAddress.mohalla} onChange={handleInputChange} className="form-control" required />
                                                </div>
                                            </div>

                                            {/* - Ward */}
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input-field d-flex">
                                                    <label>Ward:</label>
                                                    <input type="text" name="presentAddress.ward" value={formValues.presentAddress.ward} onChange={handleInputChange} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input-field d-flex">
                                                    <label> Pincode:</label>
                                                    <input type="text" name="presentAddress.pinCode" value={formValues.presentAddress.pinCode} onChange={handleInputChange} className="form-control" />
                                                </div>
                                            </div>

                                            {/* State */}
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input-field ">
                                                    <label>State:</label>
                                                    <select
                                                        className="form-control"
                                                        name="presentAddress.state"
                                                        value={formValues.presentAddress.state}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value="">--Select State--</option>
                                                        {states.map((state) => (
                                                            <option key={state._id} value={state._id}>
                                                                {state.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>

                                            {/* State */}
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input-field">
                                                    <label>District:</label>
                                                    <select
                                                        className="form-control"
                                                        name="presentAddress.district"
                                                        value={formValues.presentAddress.district}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value="">--Select District--</option>
                                                        {districts.map((district) => (
                                                            <option key={district._id} value={district._id}>
                                                                {district.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input-field">
                                                    <label>Police Station:</label>
                                                    <select
                                                        className="form-control"
                                                        name="presentAddress.policeStation"
                                                        value={formValues.presentAddress.policeStation}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value="">--Select policeStation--</option>
                                                        {policeStations.map((policeStation) => (
                                                            <option key={policeStation._id} value={policeStation._id}>
                                                                {policeStation.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>

                                            <br />

                                            {/* Permanent Address - Mohalla */}
                                            <h4 style={{color:'#677788'}}><strong>Permanent Address:</strong></h4><br />
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input-field d-flex">
                                                    <label> Mohalla:</label>
                                                    <input type="text" name="permanentAddress.mohalla" value={formValues.permanentAddress.mohalla} onChange={handleInputChange} className="form-control" required />
                                                </div>
                                            </div>

                                            {/*  - Ward */}
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input-field d-flex">
                                                    <label> Ward:</label>
                                                    <input type="text" name="permanentAddress.ward" value={formValues.permanentAddress.ward} onChange={handleInputChange} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input-field d-flex">
                                                    <label> Pincode:</label>
                                                    <input type="text" name="permanentAddress.pinCode" value={formValues.permanentAddress.pinCode} onChange={handleInputChange} className="form-control" />
                                                </div>
                                            </div>
                                          

                                            {/* State */}
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input-field">
                                                    <label>State</label>
                                                    <select
                                                        className="form-control"
                                                        name="permanentAddress.state"
                                                        value={formValues.permanentAddress.state}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value="">--Select State--</option>
                                                        {states.map((state) => (
                                                            <option key={state._id} value={state._id}>
                                                                {state.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input-field">
                                                    <label>District</label>
                                                    <select
                                                        className="form-control"
                                                        name="permanentAddress.district"
                                                        value={formValues.permanentAddress.district}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value="">--Select District--</option>
                                                        {districts.map((district) => (
                                                            <option key={district._id} value={district._id}>
                                                                {district.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input-field">
                                                    <label>Police Station</label>
                                                    <select
                                                        className="form-control"
                                                        name="permanentAddress.policeStation"
                                                        value={formValues.permanentAddress.policeStation}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value="">--Select policeStation--</option>
                                                        {policeStations.map((policeStation) => (
                                                            <option key={policeStation._id} value={policeStation._id}>
                                                                {policeStation.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>

                                            {/* family member */}
                                            <h4 style={{color:'#677788'}}><strong>Family Members:</strong></h4><br />
                                            {formValues.familyMembers.map((member, index) => (
                                                <div key={index} className="family-member">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-6">
                                                            <div className="input-field">
                                                                <label>Name</label>
                                                                <input
                                                                    type="text"
                                                                    name="name"
                                                                    placeholder="Name"
                                                                    value={member.name}
                                                                    onChange={(e) => handleFamilyMemberChange(index, e)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-6">
                                                            <div className="input-field">
                                                                <label>Age</label>
                                                                <input
                                                                    type="text"
                                                                    name="age"
                                                                    placeholder="Age"
                                                                    value={member.age}
                                                                    onChange={(e) => handleFamilyMemberChange(index, e)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-6">
                                                            <div className="input-field">
                                                                <label>Relation</label>
                                                                <input
                                                                    type="text"
                                                                    name="relation"
                                                                    placeholder="Relation"
                                                                    value={member.relation}
                                                                    onChange={(e) => handleFamilyMemberChange(index, e)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-6">
                                                            <div className="input-field">
                                                                <label>mobile No</label>
                                                                <input
                                                                    type="text"
                                                                    name="mobileNo"
                                                                    placeholder="Mobile No"
                                                                    value={member.mobileNo}
                                                                    onChange={(e) => handleFamilyMemberChange(index, e)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-6">
                                                            <div className="input-field">
                                                                <label>Aadhar</label>
                                                                <input
                                                                    type="text"
                                                                    name="aadhar"
                                                                    placeholder="Aadhar No"
                                                                    value={member.aadhar}
                                                                    onChange={(e) => handleFamilyMemberChange(index, e)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="button" className='btn btn-danger' onClick={() => handleRemoveFamilyMember(index)}>
                                                        Remove
                                                    </button>
                                                </div>
                                            ))}
                                            <div className='col-lg-12'>
                                                <button type="button" className='submit-green-btn' onClick={handleAddFamilyMember}>
                                                    Add
                                                </button>
                                            </div>

                                            <div className='clear-flex'></div>
                                            <br />
                                            <div className="col-lg-3 col-md-6">
                                                <div className="input-field d-flex">
                                                    <label>Id Type:</label>
                                                    <input type="text" name="idType" value={formValues.idType} onChange={handleInputChange} className="form-control" required />
                                                </div>
                                            </div>

                                            {/* Profile Picture */}
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input-field">
                                                    <label>Upload<span className="red">*</span></label>
                                                    <input type="file" accept=".jpeg, .jpg" name="image" className="form-control" onChange={handleImageChange} />
                                                    <div className="file-preview text-center">
                                                        <img id="uploadFile" src={previewImage} alt="your image" />
                                                    </div>
                                                </div>
                                                {/* <div className="input-field">
                                                    <label>Profile Picture</label>
                                                    <div className="profile-img">
                                                        <img src={previewImage} alt="Preview" />
                                                        <input type="file" className="form-control" onChange={handleImageChange} />
                                                    </div>
                                                </div> */}
                                            </div>


                                            {/* Submit Button */}
                                            <div className="col-lg-12">
                                                <div>
                                                    <button type="submit" className="submit-green-btn">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

ProfileUpdate.propTypes = {
    selectedData: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default ProfileUpdate;
