import React, { useEffect, useState } from 'react';
import useAsync from '../hooks/useAsync';
import AdminServices from '../services/AdminServices';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';

function Dashboard() {
  const [chartData, setChartData] = useState({
    categories: [],
    series: [],
    seriesTrainer: [],
    seriesApointment: [],
    seriesService: [],
    seriesTopThana: [],
    seriesTopUser: []
  });

  const { data: dashboardData } = useAsync(AdminServices.dashboard);
  const { data: topThanaData } = useAsync(AdminServices.top5Thana);
  const { data: topUserData } = useAsync(AdminServices.top5User);

  useEffect(() => {
    if (dashboardData && dashboardData.data) {
      const months = Array.from({ length: 12 }, (_, index) => moment().month(index).format('MMM'));
      const userCounts = new Array(12).fill(0);
      const userCounts2 = new Array(12).fill(0);
      const userCounts3 = new Array(12).fill(0);
      const userCounts4 = new Array(12).fill(0);

      dashboardData.data.user?.forEach(user => {
        const month = moment(user.createdAt).month();
        userCounts[month]++;
      });

      dashboardData.data.trainer?.forEach(user => {
        const month = moment(user.createdAt).month();
        userCounts2[month]++;
      });

      dashboardData.data.serviceCenters?.forEach(user => {
        const month = moment(user.createdAt).month();
        userCounts3[month]++;
      });

      dashboardData.data.apointment?.forEach(user => {
        const month = moment(user.createdAt).month();
        userCounts4[month]++;
      });

      setChartData(prev => ({
        ...prev,
        categories: months,
        series: [{ name: 'User', data: userCounts }],
        seriesTrainer: [{ name: 'Trainer', data: userCounts2 }],
        seriesApointment: [{ name: 'Apointment', data: userCounts4 }],
        seriesService: [{ name: 'Service Center', data: userCounts3 }],
      }));
    }
  }, [dashboardData]);

  useEffect(() => {
    if (topThanaData?.data) {
      const thanaNames = topThanaData.data.map(thana => thana?.policeStationDetails?.name || 'Unknown');
      const profileCounts = topThanaData.data.map(thana => thana?.totalProfiles || 0);

      setChartData(prev => ({
        ...prev,
        seriesTopThana: [{ name: 'Total Profiles', data: profileCounts }],
        categoriesThana: thanaNames
      }));
    }
  }, [topThanaData]);

  useEffect(() => {
    if (topUserData?.data) {
      const userNames = topUserData.data.map(user => user?.userDetails?.name || 'Unknown');
      const profileCounts = topUserData.data.map(user => user?.totalProfiles || 0);

      setChartData(prev => ({
        ...prev,
        seriesTopUser: [{ name: 'Total Profiles', data: profileCounts }],
        categoriesUser: userNames
      }));
    }
  }, [topUserData]);

  const chartOptionsThana = {
    chart: { type: 'bar' },
    plotOptions: { bar: { horizontal: false } },
    dataLabels: { enabled: false },
    xaxis: { categories: chartData.categoriesThana || [],
      labels: {
        style: {
          fontWeight: 'bold'
        }
      }
     },
     yaxis: {
      labels: {
        style: {
          fontWeight: 'bold'
        }
      }
    }
  };

  const chartOptionsUser = {
    chart: { type: 'bar' },
    plotOptions: { bar: { horizontal: false } },
    dataLabels: { enabled: false },
    xaxis: { categories: chartData.categoriesUser || [],
      labels: {
        style: {
          fontWeight: 'bold'
        }
      }
     },
    yaxis: {
      labels: {
        style: {
          fontWeight: 'bold'
        }
      }
    }
  };

  return (
    <div className="main_container">
      <div className="right_col " role="main">
        <div className="top-sec-heading">
          <h3>Welcome, {localStorage.getItem('name')}, {localStorage.getItem('district')}.</h3>
          <p>Hello, here is what happening with your app today.</p>
        </div>
        <div className="container-box">
          <div className="container-box-top-header">
            <div className="container-box-top-header-left">
              <span><img src="img/chart.svg" alt="" /></span>
              <p>Statistics</p>
            </div>
          </div>
          <div className="container-box-inner">
            <div className="row">
              <div className="col-md-4">
                <div className="g-box g-box1">
                  <a href="javascript:void(0)">
                    <p>Total Verification
                    </p><h4>{dashboardData?.data?.totalProfiles}</h4>
                    <p>Today's verification
                    </p>
                    <div className="g-box-inner">
                      <h4>{dashboardData?.data?.todayProfiles}</h4>
                      <img src="img/total-customer.svg" alt="" />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-md-4">
                <div className="g-box g-box7">
                  <a href="javascript:void(0)">
                    <p>Total Verified</p>   <h4>{dashboardData?.data?.totalVerified}</h4>
                    <p>Today's Verified</p>
                    <div className="g-box-inner">
                      <h4>{dashboardData?.data?.todayVerified}</h4>
                      <img src="img/query.svg" alt="" />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-md-4">
                <div className="g-box g-box2">
                  <a href="javascript:void(0)">
                    <p>Total Pending Verification</p>  <h4>{dashboardData?.data?.totalUnverified}</h4>
                    <p>Today's Pending Verification</p>
                    <div className="g-box-inner">
                      <h4>{dashboardData?.data?.todayUnverified}</h4>
                      <img src="img/query.svg" alt="" />
                    </div>
                  </a>
                </div>
              </div>
            
            </div>
          </div>
        </div>
          <div className="container-box">
          <p><strong>Top Performing Thana</strong>
          </p>
          <ReactApexChart options={chartOptionsThana} series={chartData.seriesTopThana} type="bar" height={350} />
        </div>
        <div className="container-box">
          <p><strong>Top Performing App User</strong>
          </p>
          <ReactApexChart options={chartOptionsUser} series={chartData.seriesTopUser} type="bar" height={350} />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
